import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { validUsername } from '@/utils/validate';
import LangSelect from '@/components/LangSelect';
import request from '@/utils/request';
// import { log } from 'util'

export default {
  name: 'Login',
  components: {
    LangSelect: LangSelect
  },
  data: function data() {
    var _this = this;
    var validateUsername = function validateUsername(rule, value, callback) {
      if (!validUsername(value)) {
        callback(new Error(_this.$t('rules.login.username.required')));
      } else {
        callback();
      }
    };
    var validatePassword = function validatePassword(rule, value, callback) {
      if (value.trim().length == 0) {
        callback(new Error(_this.$t('rules.login.password.required')));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        username: this.$store.getters.username || 'admin',
        password: ''
      },
      loginRules: {
        username: [{
          required: true,
          trigger: 'blur',
          validator: validateUsername
        }],
        password: [{
          required: true,
          trigger: 'blur',
          validator: validatePassword
        }]
      },
      passwordType: 'password',
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {},
      buttonDisabled: false,
      showMsg: '',
      modifyPassDialogVisible: false,
      newPass2: null,
      newPass1: null,
      systemName: ''
    };
  },
  watch: {
    $route: {
      handler: function handler(route) {
        var query = route.query;
        if (query) {
          if (query.m) {
            this.$notify({
              title: this.$t('notification.error.title'),
              message: this.$t('login.token_timeout'),
              type: 'error',
              duration: 5000
            });
            delete query.m;
          }
          this.redirect = query.redirect;
          this.otherQuery = this.getOtherQuery(query);
        }
      },
      immediate: true
    }
  },
  created: function created() {
    this.$store.dispatch('tableDataDictionary/clearTableDataDictionary');
    this.$store.dispatch('codeDataDictionary/clearCodeDataDictionary');
    this.getSystemName();
  },
  mounted: function mounted() {
    if (this.loginForm.username === '') {
      this.$refs.username.focus();
    } else if (this.loginForm.password === '') {
      this.$refs.password.focus();
    }
  },
  destroyed: function destroyed() {
    // window.removeEventListener('storage', this.afterQRScan)
  },
  methods: {
    getSystemName: function getSystemName() {
      var url = window.location.href;
      if (url.includes('demo') || url.includes('139.196.123.239')) {
        this.systemName = this.$t('index.system_name2');
      } else {
        this.systemName = this.$t('index.system_name1');
      }
    },
    forgetPass: function forgetPass() {
      this.$router.push('/password/forget');
    },
    checkCapslock: function checkCapslock() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        shiftKey = _ref.shiftKey,
        key = _ref.key;
      if (key && key.length === 1) {
        if (shiftKey && key >= 'a' && key <= 'z' || !shiftKey && key >= 'A' && key <= 'Z') {
          this.capsTooltip = true;
        } else {
          this.capsTooltip = false;
        }
      }
      if (key === 'CapsLock' && this.capsTooltip === true) {
        this.capsTooltip = false;
      }
    },
    showPwd: function showPwd() {
      var _this2 = this;
      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }
      this.$nextTick(function () {
        _this2.$refs.password.focus();
      });
    },
    handleLogin: function handleLogin() {
      var _this3 = this;
      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          _this3.loading = true;
          _this3.$store.dispatch('user/login', _this3.loginForm).then(function () {
            // console.log(1)
            _this3.buttonDisabled = true;
            _this3.$router.push({
              path: _this3.redirect || '/',
              query: _this3.otherQuery
            });
            _this3.loading = false;
          }).catch(function (error) {
            _this3.loading = false;
            var msg = _this3.$t('notification.loginFailed.body');
            if (error.code == '4003') {
              msg = error.message;
            } else if (error.code == '423') {
              msg = error.message;
              _this3.modifyPassDialogVisible = true;
              _this3.user_id = error.data;
            }
            _this3.$notify({
              dangerouslyUseHTMLString: true,
              title: _this3.$t('notification.loginFailed.title'),
              message: msg,
              type: 'error',
              duration: 3000
            });
            // console.log(error)
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    getOtherQuery: function getOtherQuery(query) {
      return Object.keys(query).reduce(function (acc, cur) {
        if (cur !== 'redirect') {
          acc[cur] = query[cur];
        }
        return acc;
      }, {});
    },
    confirmModifyPass: function confirmModifyPass() {
      var _this4 = this;
      var newPass1 = this.newPass1;
      var newPass2 = this.newPass2;
      if (isNull(newPass2) || isNull(newPass1)) {
        this.$message.warning(this.$t('rules.common.cannotEmpty'));
        return;
      }
      if (newPass2 != newPass1) {
        this.$message.warning(this.$t('navbar.diffPass'));
        return;
      }
      var data = {
        old_password: this.loginForm.password,
        new_password: newPass1
      };
      if (this.user_id) {
        data.user_id = this.user_id;
      }
      request({
        url: 'user/modify-password',
        method: 'post',
        data: data
      }).then(function (response) {
        if (response.data.code === '0') {
          _this4.$message.success(response.data.message);
          _this4.modifyPassDialogVisible = false;
          _this4.newPass2 = null;
          _this4.newPass = null;
          _this4.user_id = null;
        } else {
          _this4.$message.error(response.data.message);
        }
      });
    }
  }
};